<template> 
    <div id="login">
		<div class="back">
			<aside>
				<img src="../../assets/flogo.png" width="200" height="200" style="margin-top: -50px" alt="">	
				<form>
                    <h2>Request password change</h2>
					<div class="form-group">
						<span class="input">
						<input class="input_field" type="email" autocomplete="off" name="email" v-model="email">
							<label class="input_label">
								Provide registered email
							</label>
						</span>

						<span class="input">
						<input class="input_field" type="password" autocomplete="new-password" name="password" v-model="newPassword">
							<label class="input_label">
								Enter your new password
							</label>
						</span>
					</div>
					<Loading v-if="Loading" />
					<Errors v-if="errors!=''">
						<ul>
							<li>{{errors}}</li>	
						</ul>
					</Errors>
                    <Success v-if="Success">
						<ul>
							<li>Action Successful</li>	
                            <li>Check your email and spam folder to complete the process.</li>	
						</ul>
					</Success>
					<div @click.prevent="submitRequest()" v-if="!Loading"><button class="myButtoonn">Submit change request</button></div>
					<router-link :to="'/register'">
					<div class="text-center add_top_10">New to Faslearn? <strong><a >Sign up!</a></strong></div>
					</router-link>
				</form>
				<div class="copy">© 2021 Faslearn</div>
			</aside>
		</div>
	</div>
	<!-- /login -->
</template>

<script>
    import Loading from '@/components/Loading.vue'
    import Success from '@/components/Success.vue'
	import Errors from '@/components/Errors.vue'
	import { mapActions, mapGetters } from 'vuex';
    export default {
		name: 'Login',
		data(){
			return{
				email: '',
				newPassword: '',
                Loading: false,
                Success: false,
				errors: ''
			}
		},
		computed: {
			...mapGetters(['error','publicToken'])
		},
		components: {
			Errors,
            Loading,
            Success
		},
        mounted(){
			this.reset_error()
		},
		methods:{
			...mapActions(['changePasswordRequest','reset_error']),
			submitRequest() {
                this.errors = ''
				this.Loading = true
				let user = {
					email: this.email,
					newPassword: this.newPassword,
					publicToken: this.publicToken
				};
				this.changePasswordRequest(user)
				.then(res => {
                    if (res.data.success) {
                        this.Success = true
                    }else{
                        this.errors = res.data.result
                    }
					this.Loading = false
				})
			}
		}
    }
</script>

<style scoped>
      

.login-page,
.register-page {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e9ecef;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.login-box,
.register-box {
  width: 360px;
}

@media (max-width: 576px) {
  .login-box,
  .register-box {
    margin-top: .5rem;
    width: 90%;
  }
}

.login-box .card,
.register-box .card {
  margin-bottom: 0;
}

.login-card-body,
.register-card-body {
  background-color: #fff;
  border-top: 0;
  color: #666;
  padding: 20px;
}

.login-card-body .input-group .form-control,
.register-card-body .input-group .form-control {
  border-right: 0;
}

.login-card-body .input-group .form-control:focus,
.register-card-body .input-group .form-control:focus {
  box-shadow: none;
}

.login-card-body .input-group .form-control:focus ~ .input-group-prepend .input-group-text,
.login-card-body .input-group .form-control:focus ~ .input-group-append .input-group-text,
.register-card-body .input-group .form-control:focus ~ .input-group-prepend .input-group-text,
.register-card-body .input-group .form-control:focus ~ .input-group-append .input-group-text {
  border-color: #80bdff;
}

.login-card-body .input-group .form-control.is-valid:focus,
.register-card-body .input-group .form-control.is-valid:focus {
  box-shadow: none;
}

.login-card-body .input-group .form-control.is-valid ~ .input-group-prepend .input-group-text,
.login-card-body .input-group .form-control.is-valid ~ .input-group-append .input-group-text,
.register-card-body .input-group .form-control.is-valid ~ .input-group-prepend .input-group-text,
.register-card-body .input-group .form-control.is-valid ~ .input-group-append .input-group-text {
  border-color: #28a745;
}

.login-card-body .input-group .form-control.is-invalid:focus,
.register-card-body .input-group .form-control.is-invalid:focus {
  box-shadow: none;
}

.login-card-body .input-group .form-control.is-invalid ~ .input-group-append .input-group-text,
.register-card-body .input-group .form-control.is-invalid ~ .input-group-append .input-group-text {
  border-color: #dc3545;
}

.login-card-body .input-group .input-group-text,
.register-card-body .input-group .input-group-text {
  background-color: transparent;
  border-bottom-right-radius: 0.25rem;
  border-left: 0;
  border-top-right-radius: 0.25rem;
  color: #777;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login-box-msg,
.register-box-msg {
  margin: 0;
  padding: 0 20px 20px;
  text-align: center;
}

.social-auth-links {
  margin: 10px 0;
}

.myButtoonn{
		background-color: #EF6421;
		width: 100%;
		margin: 5px; 
		color: white;
		padding: 10px;
		padding-left: 30px;
		padding-right: 30px;
		border-radius: 20px;
		border: none;
	}
</style>